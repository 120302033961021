
import { defineComponent, PropType } from "vue-demi";
import { PROFILE_FINANCE_INPUTS, PROFILE_FINANCE_WITHDRAWAL_INPUTS, PROFILE_INPUTS } from "@/store/common/profile"
import CommonInputs from '@/components/common/inputs/index.vue';
import { User } from "@/interfaces/user/user.dto";
import { useAPI } from "@/use";
import { ROLES } from "@/utils/enums";

export default defineComponent({
  name: 'ProfileFinanceMain',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      amount: 0,
      finance: PROFILE_FINANCE_INPUTS(),
      withdrawal: PROFILE_FINANCE_WITHDRAWAL_INPUTS(),
      profile: PROFILE_INPUTS(this.user),
    }
  },
  created() {
    this.getFinanceMethod();
  },
  methods: {
    async getFinanceMethod() {
      const result = await useAPI().user.getFinanceMethod();
      this.finance = PROFILE_FINANCE_INPUTS(result.data);
      this.amount = result.data.amount;
    },
    async submit() {
      if (!this.finance.data.type) return this.notification('Выберите тип', 'error');
      if (!this.finance.data.name) return this.notification('Заполните название компании', 'error');
      if (!this.finance.data.INN) return this.notification('Введите ИНН', 'error');
      if (!this.finance.data.BIK) return this.notification('Введите БИК банка', 'error');
      if (!this.finance.data.account) return this.notification('Введите номер счета', 'error');
      if (!this.finance.data.address) return this.notification('Введите адрес', 'error');

      await useAPI().user.updateFinanceMethod(this.finance.data);
      await useAPI().user.applicationPartner();
    },
    async submitWithdrawal() {
      if (!this.withdrawal.data.amount)
        return this.notification('Введите корректную сумму!', 'error');
      if (Number(this.withdrawal.data.amount) < 5000)
        return this.notification('Вы можете вывести сумму от 5000 рублей!', 'error');
      
      await useAPI().user.createPaymentMethod({ amount: Number(this.withdrawal.data.amount) });
    },
    notification(message: string, status: string) {
      this.$store.commit('createNotification', {
        message,
        status
      })
    }
  },
  computed: {
    isPartner() {
      return this.profile.data.verification;
    }
  },
  components: {
    CommonInputs
  }
})
